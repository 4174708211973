import React, { Fragment, useState } from "react";
import { makeStyles, Paper, Avatar, Grid, Typography, Menu, MenuItem, Modal, Slide, IconButton } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import CloseIcon from "@material-ui/icons/Close";
import CandidateProfile from "../CandidateProfile";
import { Draggable } from "react-beautiful-dnd";
import { Scrollbars } from "react-custom-scrollbars";
import oauthConfig from "../../../oauthConfig";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    background: "#ffffff",
    minHeight: "160px",
    border: "1px solid lightgrey",
    borderRadius: "2px",
    marginBottom: "8px",
    padding: "8px",
    transition: "background-color 0.5s linear",
    "&.active": {
      backgroundColor: "#EBEBEB",
    },
    "&:hover": {
      backgroundColor: "#EBEBEB",
      "& $userMenu": {
        visibility: "visible",
      },
    },
  },
  iconText: {
    display: "flex",
    alignItems: "center",
  },
  skill: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    background: theme.palette.grey[400],
    color: "#fff",
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0`,
    borderRadius: "2px",
  },
  modal: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(0)}px ${theme.spacing(1)}px`,
    "&:focus": {
      outline: "none",
    },
  },
  modalContent: {
    maxWidth: "700px",
    margin: "0 auto",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    "&:focus": {
      outline: "none",
    },
  },
  userMenu: {
    position: "absolute",
    top: "4px",
    right: "10px",
    borderRadius: "5px",
    visibility: "hidden",
  },
  modalProfile: {
    padding: "15px",
  },
}));

const User = (props) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const openProfile = (id) => {
    if (props.col === "rejected" || props.col === "requested") {
      return;
    }
    setOpenModal(id);
    setAnchorEl(null);
  };

  const closeProfile = () => {
    setOpenModal(0);
  };

  const openMenu = (target) => {
    setAnchorEl(target);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const archiveButton = !Boolean(props.col == "archived" || props.col == "rejected" || props.col == "requested");
  const profileButton = !Boolean(props.col == "rejected" || props.col == "requested");

  let moveButtons;

  if (props.countUsers !== 1) {
    moveButtons = (
      <div>
        <MenuItem onClick={() => props.moveTo(props.user.node_id, props.col, "top")}>Move to Top</MenuItem>
        <MenuItem onClick={() => props.moveTo(props.user.node_id, props.col, "bottom")}>Move to Bottom</MenuItem>
      </div>
    );
  }

  if (props.index == props.countUsers - 1 && props.countUsers !== 1) {
    moveButtons = <MenuItem onClick={() => props.moveTo(props.user.node_id, props.col, "top")}>Move to Top</MenuItem>;
  }

  if (props.index === 0 && props.countUsers !== 1) {
    moveButtons = <MenuItem onClick={() => props.moveTo(props.user.node_id, props.col, "bottom")}>Move to Bottom</MenuItem>;
  }

  return (
    <Fragment>
      <Draggable draggableId={props.user.node_id} index={props.index} isDragDisabled={props.col == "rejected" || props.col == "requested"}>
        {(provided, snapshot) => {
          return (
            <Paper className={clsx(classes.container, { ["active"]: snapshot.isDragging })} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
              <IconButton className={classes.userMenu} onClick={(e) => openMenu(e.currentTarget)} size="small" children={<MoreHorizIcon />}></IconButton>
              <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={closeMenu} onClick={closeMenu}>
                {profileButton && <MenuItem onClick={() => openProfile(props.user.node_id)}>View Profile</MenuItem>}
                {archiveButton && <MenuItem onClick={() => props.archived(props.user.node_id, props.col)}>Archive</MenuItem>}
                <MenuItem onClick={() => props.delete(props.user.node_id, props.col)}>Disassociate</MenuItem>
                {moveButtons}
              </Menu>
              <Grid container direction="column" justify="center" alignItems="center" onClick={() => openProfile(props.user.node_id)}>
                {Array.isArray(props.user.field_profile_picture) || props.user.field_profile_picture === null || props.user.field_profile_picture === "" ? (
                  <Avatar>
                    <PersonIcon style={{ fontSize: 30 }} />
                  </Avatar>
                ) : (
                  <Avatar src={props.user.field_profile_picture} />
                )}
                <Typography variant="h6" align="center">
                  {props.user.field_first_name} {props.user.field_last_name}
                </Typography>
                {props.user.field_location && (
                  <div className={classes.iconText}>
                    <LocationOnIcon style={{ fontSize: 14 }} color="disabled" />
                    <Typography variant="body2">{props.user.field_location.split("--").join(", ")}</Typography>
                  </div>
                )}
                <Typography variant="caption" paragraph>
                  {props.user.field_professional_experience ? `(${props.user.field_professional_experience} years experience)` : ""}
                </Typography>
              </Grid>
              <Grid container justify="center">
                {props.user.field_technical_skills_name
                  ? props.user.field_technical_skills_name.split(";").map((el) => (
                      <div className={classes.skill} key={el}>
                        <Typography variant="caption">{el}</Typography>
                      </div>
                    ))
                  : ""}
              </Grid>
            </Paper>
          );
        }}
      </Draggable>
      <Modal open={openModal === props.user.node_id} disableScrollLock onClose={closeProfile} className={classes.modal}>
        <Slide in={openModal === props.user.node_id} direction="down">
          <Paper className={classes.modalContent}>
            <Grid container justify="flex-end">
              <IconButton onClick={closeProfile}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Scrollbars style={{ height: `85vh` }}>
              <div className={classes.modalProfile}>
                <CandidateProfile currentOrg={props.currentOrg} token={props.token} uuid={props.user.node_uuid} initialData={props.initialData} candidateDB />
              </div>
            </Scrollbars>
          </Paper>
        </Slide>
      </Modal>
    </Fragment>
  );
};

export default User;
